import React, { useState, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataContext } from "../contexts/dataContexts";
import { useNavigate } from "react-router-dom";
import WaiveReasonModal from "./WaiveReasonModal"; // Ensure the path is correct
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { useLanguage } from "../contexts/LanguageContext";

function ResultsNextSteps() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { translations } = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const { results, additionalInfo, intakeFormData, postData, setPostData } =
    useContext(DataContext);

  const {
    employerCoverageEmployeeOnly,
    coveredCAEmployeeOnly,
    employerCoverageFamily,
    coveredCAFamilyIncludingEmployee,
    coveredCAFamilyExcludingEmployee,
  } = results;

  const {
    employeeAffordable,
    familyAffordable,
    isEmployeeMedicareEligible,
    includedAges,
    mediCalEligibleAges,
    medicareEligibleAges,
  } = additionalInfo;

  const state = intakeFormData?.state?.toUpperCase();
  const householdSize = intakeFormData?.householdSize;

  const formatCost = (cost, includedAges, isTotal = false) => {
    // Check if includedAges is null or has a length of 0
    if (
      includedAges === null ||
      (Array.isArray(includedAges) && includedAges.length === 0)
    ) {
      return <span style={{ color: "#007D85", fontWeight: 500 }}>N/A</span>;
    }
    // Apply different fontWeight if it's the total cost
    const fontWeight = isTotal ? 700 : 500; // Use 700 for bolder text for total cost
    // Otherwise, return the formatted cost
    return (
      <span style={{ color: "#007D85", fontWeight }}>
        ${(Math.round(cost * 100) / 100).toFixed(2)}
      </span>
    );
  };

  const handleWaiveClick = () => {
    setShowModal(true);
  };

  const handleSaveReason = (selectedReason) => {
    const updatedPostData = {
      ...postData,
      waiverReason: selectedReason,
    };
    setPostData(updatedPostData);

    postDataToEndpoint(updatedPostData).then(() => {
      // Navigate to /confirmation only after successful post when Waive is selected
      navigate("/confirmation");
    });
  };

  const handleButtonClick = (buttonName) => {
    const updatedPostData = {
      ...postData,
      optionSelected: buttonName,
    };
    setPostData(updatedPostData);

    postDataToEndpoint(updatedPostData, buttonName);
  };

  const postDataToEndpoint = async (data, optionSelected = null) => {
    const dataToSend = {
      ...data,
      ...(optionSelected ? { optionSelected } : {}),
    };

    try {
      const response = await fetch(
        "https://m4u8nxs6t1.execute-api.us-west-2.amazonaws.com/dev/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add other headers if needed
          },
          body: JSON.stringify(dataToSend),
        }
      );
      if (response.ok) {
        console.log("Data Saved Successfully"); // Success log
      } else {
        console.error("Failed to post data", await response.text());
      }
    } catch (error) {
      console.error("There was an error posting the data", error);
    }
  };

  const getCardsToShow = () => {
    const cards = [];
    const isSingleHousehold = householdSize === "1";
    const isFamilyHousehold = householdSize !== "1";
    const eligibleForMediCareEmployee = isEmployeeMedicareEligible;
    const eligibleForMediCareFamily =
      isFamilyHousehold && isEmployeeMedicareEligible;
    const eligibleForCoveredCA =
      !employeeAffordable && isSingleHousehold && state === "CA";
    const eligibleForCoveredCAMediCal =
      !employeeAffordable &&
      isSingleHousehold &&
      (includedAges === null || includedAges.length === 0) &&
      state === "CA";
    const eligibleForCoveredCAFamily =
      !employeeAffordable &&
      !familyAffordable &&
      isFamilyHousehold &&
      includedAges != null &&
      includedAges.length > 0 &&
      state === "CA";
    const eligibleForCoveredCAFamilyMediCal =
      !employeeAffordable &&
      !familyAffordable &&
      isFamilyHousehold &&
      (includedAges === null || includedAges.length === 0) &&
      state === "CA";
    const eligibleForCoveredCASplitFamily =
      employeeAffordable &&
      !familyAffordable &&
      isFamilyHousehold &&
      includedAges != null &&
      includedAges.length > 0 &&
      state === "CA";
    const eligibleForStateSubsidized =
      !employeeAffordable &&
      isSingleHousehold &&
      isFamilyHousehold &&
      state !== "CA";
    const eligibleForStateSubsidizedFamily =
      !employeeAffordable &&
      !familyAffordable &&
      isFamilyHousehold &&
      state !== "CA";
    const eligibleForSplitStateSubsidized =
      employeeAffordable &&
      !familyAffordable &&
      isFamilyHousehold &&
      state !== "CA";

    // Medicare or Corporate Benefits for single household
    if (eligibleForMediCareEmployee) {
      cards.push("MediCareEmployee");
    } else {
      cards.push("AkashCorporateBenefits");
    }

    // Medicare or Corporate Benefits for family household
    if (eligibleForMediCareFamily) {
      cards.push("MediCareFamily");
    } else if (isFamilyHousehold) {
      cards.push("AkashCorporateBenefitsFamily");
    }

    // Covered California and Medi-Cal options
    if (eligibleForCoveredCA) {
      cards.push("CoveredCA");
    } else if (eligibleForCoveredCAMediCal) {
      cards.push("CoveredCAMediCal");
    } else if (eligibleForCoveredCAFamily) {
      cards.push("CoveredCAFamilyIncludingEmployee");
    } else if (eligibleForCoveredCAFamilyMediCal) {
      cards.push("CoveredCAFamilyMediCal");
    } else if (eligibleForCoveredCASplitFamily) {
      cards.push("CoveredCASplitFamily");
    }

    // State Subsidized option
    if (eligibleForStateSubsidizedFamily) {
      cards.push("StateSubsidizedFamily");
    } else if (eligibleForSplitStateSubsidized) {
      cards.push("StateSubsidizedSplitFamily");
    } else if (eligibleForStateSubsidized) {
      cards.push("StateSubsidized");
    }

    // Always show the option to waive coverage
    cards.push("WaiveCoverage");

    return cards;
  };

  const cardsToShow = getCardsToShow();

  const cardContent = {
    AkashCorporateBenefits: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {translations["Akash Corporate Health Benefits"]}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee Only"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 4 }}>
            {translations["Monthly cost:"]}{" "}
            {formatCost(employerCoverageEmployeeOnly.cost)}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://enrollme.sureco.com/login"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("Akash Employee Only");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    MediCareEmployee: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {translations["Akash Corporate Health Benefits for Medicare"]}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee Only"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 4 }}>
            {
              translations[
                "Elect Medicare in the Enrollment Platform and a SureCo Employee Experience Specialist will call you to complete the process."
              ]
            }
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://enrollme.sureco.com/login"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("MediCare Akash Benefits Employee Only");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    CoveredCA: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Covered California
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee Only"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 4 }}>
            {translations["Monthly cost:"]}{" "}
            {formatCost(coveredCAEmployeeOnly.cost, includedAges)}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://mobile.sureco.com/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("CCA Employee Only");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    CoveredCAMediCal: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Covered California
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee Only"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 4 }}>
            {
              translations[
                "You may qualify for free or low-cost coverage through Medi-Cal."
              ]
            }
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://mobile.sureco.com/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("CCA Medi-Cal EE");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    AkashCorporateBenefitsFamily: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {translations["Akash Corporate Health Benefits"]}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee + Family"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 4 }}>
            {translations["Monthly cost:"]}{" "}
            {formatCost(employerCoverageFamily.cost)}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://enrollme.sureco.com/login"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("Akash Employee + Family");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    MediCareFamily: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {translations["Akash Corporate Health Benefits for Medicare"]}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee + Family"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 2 }}>
            {translations["Monthly cost for dependent coverage:"]}{" "}
            {formatCost(employerCoverageFamily.cost)}
          </Typography>
          <Typography
            variant="caption"
            sx={{ mt: 3, mb: 4, fontStyle: "italic", display: "block" }}
          >
            {
              translations[
                "Monthly cost for Medicare coverage varies. Elect Medicare in the Enrollment Platform, enroll the rest of your dependents, and a SureCo Employee Experience Specialist will call you to complete the Medicare process."
              ]
            }
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://enrollme.sureco.com/login"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("Akash Family Medicare");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    CoveredCAFamilyIncludingEmployee: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Covered California
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee + Family"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 4 }}>
            {translations["Monthly cost:"]}{" "}
            {formatCost(coveredCAFamilyIncludingEmployee.cost, includedAges)}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://mobile.sureco.com/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("CCA Family Including EE");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    CoveredCASplitFamily: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {translations["Akash Corporate & Covered California"]}{" "}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee on Akash + Family on Covered California"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2">
            {translations["Monthly cost for employee coverage:"]}{" "}
            {formatCost(employerCoverageEmployeeOnly.cost)}
          </Typography>
          <Typography variant="body2">
            {translations["Monthly cost for dependent coverage:"]}{" "}
            {formatCost(coveredCAFamilyExcludingEmployee.cost, includedAges)}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {translations["Total monthly cost:"]}{" "}
            {formatCost(
              employerCoverageEmployeeOnly.cost +
                coveredCAFamilyExcludingEmployee.cost,
              includedAges,
              true // This indicates that the cost being formatted is the total cost
            )}
          </Typography>
          <Typography
            variant="caption"
            sx={{ mt: 3, mb: 4, fontStyle: "italic", display: "block" }}
          >
            {
              translations[
                "Note: After enrolling dependents in State Benefits, you'll be redirected to our platform for your Akash enrollment. If not,"
              ]
            }{" "}
            <a href="https://enrollme.sureco.com/login">
              {translations["click here"]}
            </a>
            .
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://mobile.sureco.com/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("CCA Split Family");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    StateSubsidizedSplitFamily: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 600, mb: 2 }}
          >
            {translations["Akash Corporate & State Exchange"]}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee on Akash + Family on State"]}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body2">
            {translations["Monthly cost for employee:"]}{" "}
            {formatCost(employerCoverageEmployeeOnly.cost)}
          </Typography>
          <Typography variant="body2">
            {translations["Monthly cost for dependent(s): Varies"]}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography
            variant="caption"
            sx={{ fontStyle: "italic", display: "block", mb: 2 }}
          >
            {
              translations[
                "Note: After enrolling yourself in Akash Corporate Benefits, call or email to enroll your dependents in State Coverage Exchange Benefits."
              ]
            }
          </Typography>
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <PhoneIcon sx={{ mr: 1 }} />
            <a
              href="tel:+19493295880"
              style={{ textDecoration: "none", ml: 1, color: "#007D85" }}
            >
              (949) 329-5880
            </a>
          </Typography>
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center", mb: 2 }}
          >
            <EmailIcon sx={{ mr: 1 }} />
            <a
              href="mailto:employee.experience@sureco.com"
              style={{ textDecoration: "none", ml: 1, mb: 4, color: "#007D85" }}
            >
              employee.experience@sureco.com
            </a>
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://enrollme.sureco.com/login"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("Non-CA Split Family");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),
    StateSubsidizedFamily: (
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between", // Ensures spacing is distributed
        }}
      >
        <div>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {translations["State Coverage Exchange Benefits"]}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {translations["Employee + Family"]}
          </Typography>
        </div>
        <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
        <div>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>{translations["For more information:"]}</strong>
          </Typography>
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
          >
            <PhoneIcon sx={{ mr: 1 }} />
            {translations["Call"]}
            <a
              href="tel:+19493295880"
              style={{
                textDecoration: "none",
                color: "#007D85",
                marginLeft: 4,
              }}
            >
              (949) 329-5880
            </a>
          </Typography>
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <EmailIcon sx={{ mr: 1 }} />
            {translations["Email"]}
            <a
              href="mailto:employee.experience@sureco.com"
              style={{
                textDecoration: "none",
                color: "#007D85",
                marginLeft: 4,
              }}
            >
              employee.experience@sureco.com
            </a>
          </Typography>
        </div>
      </CardContent>
    ),
    StateSubsidized: (
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between", // Ensures spacing is distributed
        }}
      >
        <div>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {translations["State Coverage Exchange Benefits"]}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {translations["Employee Only"]}
          </Typography>
        </div>
        <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
        <div>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>{translations["For more information:"]}</strong>
          </Typography>
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
          >
            <PhoneIcon sx={{ mr: 1 }} />
            {translations["Call"]}
            <a
              href="tel:+19493295880"
              style={{
                textDecoration: "none",
                color: "#007D85",
                marginLeft: 4,
              }}
            >
              (949) 329-5880
            </a>
          </Typography>
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <EmailIcon sx={{ mr: 1 }} />
            {translations["Email"]}
            <a
              href="mailto:employee.experience@sureco.com"
              style={{
                textDecoration: "none",
                color: "#007D85",
                marginLeft: 4,
              }}
            >
              employee.experience@sureco.com
            </a>
          </Typography>
        </div>
      </CardContent>
    ),

    CoveredCAFamilyMediCal: (
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between", // Ensures spacing is distributed
        }}
      >
        <div>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Covered California
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translations["Employee + Family"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 4 }}>
            {
              translations[
                "You and your family may qualify for free or low-cost coverage through Medi-Cal."
              ]
            }
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          href="https://mobile.sureco.com/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            handleButtonClick("CCA Family including EE MediCal");
          }}
        >
          {translations["Enroll Now"]}
        </Button>
      </CardContent>
    ),

    WaiveCoverage: (
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div sx={{ flexGrow: 1 }}>
          <Typography
            variant="body1"
            sx={{ mb: 2 }}
            style={{ fontWeight: 600 }}
          >
            {translations["Waive Coverage"]}
          </Typography>
          <Divider sx={{ my: 2 }} /> {/* Adds a visual divider */}
          <Typography variant="body2" sx={{ mb: 4 }}>
            {
              translations[
                "If you do not wish to enroll in a medical plan for yourself or your dependents for 2024, you must waive your right to coverage."
              ]
            }
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "auto", mb: 2, textTransform: "none", ml: 8, mr: 8 }}
          onClick={handleWaiveClick}
        >
          {translations["Waive Now"]}
        </Button>
      </CardContent>
    ),
  };

  const renderCard = (cardKey) => (
    <Grid item xs={12} sm={6} md={4} key={cardKey}>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {cardContent[cardKey]}
      </Card>
    </Grid>
  );

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ fontSize: theme.typography.pxToRem(25) }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {translations["Total Estimated Monthly Costs & "]}
            <br /> {translations["Next Steps"]}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {cardsToShow.map((cardKey) => renderCard(cardKey))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <WaiveReasonModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSaveReason={handleSaveReason}
        postDataToEndpoint={postDataToEndpoint}
      />
    </>
  );
}

export default ResultsNextSteps;
