import React, { createContext, useState } from "react";

// Define the shape of the context
export const DataContext = createContext({
  employeeData: {
    employeeID: null,
    employmentType: "",
    state: "",
    zipCode: "",
    householdSize: "",
  },
  setEmployeeData: () => {},

  //Post Data
  postData: [],
  setPostData: () => {},

  // For the results after performing calculations
  results: {
    employerCoverageEmployeeOnly: {
      calculated: false,
      cost: null,
      ages: [],
    },
    coveredCAEmployeeOnly: {
      calculated: false,
      cost: null,
      ages: [],
    },
    mediCalEmployeeOnly: {
      calculated: false,
      eligible: false,
      ages: [],
    },
    employerCoverageFamily: {
      calculated: false,
      cost: null,
      ages: [],
    },
    coveredCAFamilyIncludingEmployee: {
      calculated: false,
      cost: null,
      ages: [],
    },
    coveredCAFamilyExcludingEmployee: {
      calculated: false,
      cost: null,
      ages: [],
    },
    mediCalFamily: {
      calculated: false,
      eligible: false,
      ages: [],
    },
  },
  setResults: () => {},

  // Additional information after calculations
  additionalInfo: {
    mediCalEligibleAges: [],
    medicareEligibleAges: [],
    employeeAffordable: false,
    familyAffordable: false,
    excludedAges: [],
    includedAges: [],
    isEmployeeMedicareEligible: false,
    medicareEligibleFamilyMembers: [],
    employeeAffordablePercentage: null,
    famillyAffordablePercentage: null,
  },
  setAdditionalInfo: () => {},
});

export const DataProvider = ({ children }) => {
  const [employeeData, setEmployeeData] = useState({
    employmentType: "",
    state: "",
    zipCode: "",
    householdSize: "",
  });

  // Initialize the results state
  const [results, setResults] = useState({
    employerCoverageEmployeeOnly: {
      calculated: false,
      cost: null,
      ages: [],
    },
    coveredCAEmployeeOnly: {
      calculated: false,
      cost: null,
      ages: [],
    },
    mediCalEmployeeOnly: {
      calculated: false,
      eligible: false,
      ages: [],
    },
    employerCoverageFamily: {
      calculated: false,
      cost: null,
      ages: [],
    },
    coveredCAFamilyIncludingEmployee: {
      calculated: false,
      cost: null,
      ages: [],
    },
    coveredCAFamilyExcludingEmployee: {
      calculated: false,
      cost: null,
      ages: [],
    },
    mediCalFamily: {
      calculated: false,
      eligible: false,
      ages: [],
    },
  });

  // Initialize the additional information state
  const [additionalInfo, setAdditionalInfo] = useState({
    mediCalEligibleAges: [],
    employeeAffordable: false,
    familyAffordable: false,
    householdSize: "",
    includedAges: [],
    excludedAges: [],
    isEmployeeMedicareEligible: false,
    medicareEligible: [],
    employeeAffordablePercentage: null,
    famillyAffordablePercentage: null,
  });

  //initialize the utm data state for utm_source
  const [utmData, setUtmData] = useState("");

  //intake form data
  const [intakeFormData, setIntakeFormData] = useState(null);

  //Post Data
  const [postData, setPostData] = useState([]);

  return (
    <DataContext.Provider
      value={{
        employeeData,
        setEmployeeData,
        results,
        setResults,
        additionalInfo,
        setAdditionalInfo,
        utmData,
        setUtmData,
        intakeFormData,
        setIntakeFormData,
        postData,
        setPostData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
