import React, { useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import { DataContext } from "../contexts/dataContexts";
import { useLanguage } from "../contexts/LanguageContext";
function WaiveReasonModal({ open, onClose, onSaveReason, postDataToEndpoint }) {
  const { postData, setPostData } = useContext(DataContext);
  const { translations } = useLanguage();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { waiverReason } = data;
    const updatedPostData = {
      ...postData,
      waiverReason: waiverReason,
    };
    setPostData(updatedPostData);

    // Call onSaveReason to handle the selected reason
    onSaveReason(waiverReason);

    // Check if postDataToEndpoint is a function before calling
    if (typeof postDataToEndpoint === "function") {
      await postDataToEndpoint(updatedPostData);
    }

    // Close the modal
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2, // padding shorthand for paddingTop, paddingBottom, paddingLeft, paddingRight
          backgroundColor: "white",
          outline: "none",
          width: { xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" }, // Responsive width
          maxWidth: "400px", // Max width to prevent the modal from being too wide on large screens
          borderRadius: "10px",
          boxShadow: 24, // Optional: Adds shadow to the modal box for better UI
          mx: "auto", // Horizontal margin auto for centering
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>{translations["Select a reason for waiving:"]}</h3>
          <Controller
            name="waiverReason"
            control={control}
            defaultValue=""
            rules={{
              required:
                translations["Please select a reason before confirming"],
            }}
            render={({ field }) => (
              <Select
                {...field}
                labelId="waiverReason-label"
                displayEmpty
                variant="outlined"
                error={!!errors.waiverReason}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getcontentanchorel: null, // This is important to make the menu display below the input
                }}
              >
                <MenuItem value="I receive benefits through another employer">
                  {translations["I receive benefits through another employer"]}
                </MenuItem>
                <MenuItem value="I receive benefits through Medicaid/Medicare">
                  {translations["I receive benefits through Medicaid/Medicare"]}
                </MenuItem>
                <MenuItem value="I am enrolled in another Individual Plan">
                  {translations["I am enrolled in another Individual Plan"]}
                </MenuItem>
                <MenuItem value="I receive coverage under my parent’s plan">
                  {translations["I receive coverage under my parent’s plan"]}
                </MenuItem>
                <MenuItem value="I receive coverage under my spouse’s plan">
                  {translations["I receive coverage under my spouse’s plan"]}
                </MenuItem>
                <MenuItem value="Other">{translations["Other"]}</MenuItem>
              </Select>
            )}
          />
          {errors.waiverReason && (
            <Typography color="error" variant="body2" style={{ marginTop: 4 }}>
              {errors.waiverReason.message}
            </Typography>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: 20, marginBottom: 10 }}
          >
            {translations["Confirm"]}
          </Button>
        </form>
      </Box>
    </Modal>
  );
}

export default WaiveReasonModal;
