/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { DataContext } from "../contexts/dataContexts";
import { useLanguage } from "../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Container,
  CardContent,
  Select,
  MenuItem,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import zipCodeCountyMapping from "../assets/jsons/Zip_Code_County_Mapping.json";
import guidelines from "../assets/jsons/2024_Covered_California_Guidelines.json";
import fplMapping from "../assets/jsons/FPL_mapping.json";
import contributionMap from "../assets/jsons/Participating_Contribution_Map.json";
import ageFactor from "../assets/jsons/Age_Factor.json";

function IntakeForm() {
  const navigate = useNavigate();
  const { translations } = useLanguage();
  const {
    employeeData,
    setIntakeFormData,
    setResults,
    setAdditionalInfo,
    postData,
    setPostData,
  } = useContext(DataContext);

  const savedIntakeFormData =
    JSON.parse(localStorage.getItem("intakeFormData")) || employeeData;

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [employmentType, setEmploymentType] = useState(
    savedIntakeFormData?.employmentType || ""
  );
  const [state, setState] = useState(savedIntakeFormData?.state || "");
  const [zipCode, setZipCode] = useState(savedIntakeFormData?.zipCode || "");
  const [county, setCounty] = useState(savedIntakeFormData?.county || "");
  const [displayValue, setDisplayValue] = useState(
    savedIntakeFormData?.annualIncome || ""
  );
  const [annualIncome, setAnnualIncome] = useState(
    savedIntakeFormData?.annualIncome || ""
  );
  const [householdSize, setHouseholdSize] = useState(
    savedIntakeFormData?.householdSize || "1"
  );
  const [ageRelationshipPairs, setAgeRelationshipPairs] = useState(
    savedIntakeFormData?.ageRelationshipPairs || [
      { age: "", relationship: "Employee" },
    ]
  );
  const [availableCounties, setAvailableCounties] = useState([]);
  const [countyError, setCountyError] = useState("");

  const populateCounties = (selectedState, selectedZipCode) => {
    const matchingCounties = zipCodeCountyMapping.filter(
      (entry) =>
        entry.STATE === selectedState &&
        entry.ZIPCODE.toString() === selectedZipCode
    );

    const uniqueCounties = [
      ...new Set(matchingCounties.map((entry) => entry.COUNTY)),
    ];

    setAvailableCounties(uniqueCounties);

    if (uniqueCounties.length === 1) {
      setValue("county", uniqueCounties[0]); // Set the county value in the form
      setCounty(uniqueCounties[0]); // Update the local state
      setCountyError(""); // Clear the error message
    } else if (uniqueCounties.length === 0) {
      setCountyError(
        translations["Please provide a valid zip code to populate county"]
      );
    } else {
      setCountyError("");
      setValue("county", ""); // Clear the county value in the form if multiple options
    }
  };

  // Using a ref to keep track of whether state and zip have been manually set
  const manualSetRef = useRef(false);

  // Setting state and zip code from context (if available)
  useEffect(() => {
    if (
      !manualSetRef.current &&
      savedIntakeFormData.state &&
      savedIntakeFormData.zipCode
    ) {
      populateCounties(
        savedIntakeFormData.state,
        savedIntakeFormData.zipCode.toString()
      );
      setState(savedIntakeFormData.state);
      setZipCode(savedIntakeFormData.zipCode.toString());
      manualSetRef.current = true; // Indicate that the initial set was done
    }
  }, [savedIntakeFormData.state, savedIntakeFormData.zipCode]);

  useEffect(() => {
    if (zipCode.length === 5 && state) {
      populateCounties(state, zipCode);
    }
  }, [state, zipCode]);

  useEffect(() => {
    const newPairs = [];
    for (let i = 0; i < householdSize; i++) {
      if (i < ageRelationshipPairs.length) {
        newPairs.push(ageRelationshipPairs[i]);
      } else {
        newPairs.push({ age: "", relationship: i === 0 ? "Employee" : "" });
      }
    }
    setAgeRelationshipPairs(newPairs);
  }, [householdSize]);

  const relationshipValidation = {
    required: translations["Relationship is required"],
    validate: (value) => {
      if (!value) {
        return translations["Relationship is required"];
      }
      const watchedRelationships = watch((data) => {
        const relationshipKeys = Object.keys(data).filter((key) =>
          key.startsWith("relationship_")
        );
        return relationshipKeys.reduce((acc, key) => {
          acc[key] = data[key];
          return acc;
        }, {});
      });

      const spouseCount = Object.values(watchedRelationships).filter(
        (val) => val === "Spouse"
      ).length;
      return spouseCount <= 1 || "Only one spouse/partner is allowed";
    },
  };

  //Validate Age inputs
  const ageValidation = (value, index) => {
    const age = parseInt(value, 10); // Parsing since input type="number" still returns a string
    const relationship = getValues(`relationship_${index}`);

    if (isNaN(age) || age < 0 || age > 100) {
      return translations["Please enter a valid age from 0-100"];
    }
    if (relationship === "Child" && age > 25) {
      return translations["Child age must be 25 or under"];
    }
    if (relationship === "Employee" && age < 15) {
      return translations["Self age must be 15 or older"];
    }
    if (relationship === "Spouse" && age < 18) {
      return translations["Invalid Age"];
    }
    return true;
  };

  const handleInputChange = (event, onChange) => {
    // Extract only numbers
    const cleanValue = event.target.value.replace(/[^\d]/g, "");

    // Convert string to number for storage
    const intValue = cleanValue === "" ? "" : parseInt(cleanValue, 10); // Convert to int, keep as empty string if input is empty

    // Format display value with commas
    const formattedDisplayValue = new Intl.NumberFormat().format(cleanValue); // This will add commas

    // Update the form field
    onChange(intValue); // Call the default onChange with integer value

    // Update the local state
    setAnnualIncome(intValue); // Update the local state with integer value

    // Update the display value
    setDisplayValue(formattedDisplayValue); // Keep the display value with commas
  };

  const sendPostDataToBackend = async (dataToSend) => {
    try {
      const response = await fetch(
        "https://m4u8nxs6t1.execute-api.us-west-2.amazonaws.com/dev/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Failed to save data.");
      }

      console.log("Data saved successfully:", responseData);
    } catch (error) {
      console.error("Error:", error.message);
      alert("Failed to save data. Please try again.");
    }
  };

  // This effect runs once when the component mounts
  useEffect(() => {
    // Check if local storage already has the form data
    if (!localStorage.getItem("intakeFormData") && employeeData) {
      // No existing data, save employeeData to local storage
      localStorage.setItem("intakeFormData", JSON.stringify(employeeData));
    }
  }, []);

  const [ccaRates, setCcaRates] = useState(null);
  const [ichraPlan, setIchraPlan] = useState(null);
  const [akashPlanPegging, setAkashPlanPegging] = useState(null);

  useEffect(() => {
    const loadJsonData = async () => {
      try {
        const rates = await import(
          "../assets/jsons/2024_CCA_SLCS_Rates_modified.json"
        );
        setCcaRates(rates.default);

        const ichra = await import(
          "../assets/jsons/ICHRA_Plan_Pegging_LCS_AKASH.json"
        );
        setIchraPlan(ichra.default);

        const akashPegging = await import(
          "../assets/jsons/akash_plan_pegging.json"
        );
        setAkashPlanPegging(akashPegging.default);
      } catch (error) {
        // console.error("Failed to load CCA rates", error);
        console.error("Failed to load JSON data", error);
      }
    };
    loadJsonData();
  }, []);

  const performCalculations = (data) => {
    if (!county || !state || !zipCode || !employmentType) {
      console.error("Essential data is missing.");
      return;
    }

    const employerCoverageResults = calculateEmployerCoverage();
    const coveredCAResults = calculateCoveredCACost();

    // Extract additional information from results
    const {
      employeeAffordable,
      familyAffordable,
      isEmployeeMedicareEligible,
      medicareEligible,
      employeeAffordablePercentage,
      familyAffordablePercentage,
    } = employerCoverageResults.additionalInfo;

    const { mediCalEligibleAges, includedAges, excludedAges } =
      coveredCAResults.additionalInfo;

    // Set results to context
    setResults((prevResults) => ({
      ...prevResults,
      employerCoverageEmployeeOnly: employerCoverageResults.employeeOnly,
      employerCoverageFamily: employerCoverageResults.family,
      coveredCAEmployeeOnly: coveredCAResults.employeeOnly,
      coveredCAFamilyIncludingEmployee:
        coveredCAResults.familyIncludingEmployee,
      coveredCAFamilyExcludingEmployee:
        coveredCAResults.familyExcludingEmployee,
    }));
    // Set additional information in context
    setAdditionalInfo((prevInfo) => ({
      ...prevInfo,
      employeeAffordable, // these should now be boolean values, true or false
      familyAffordable, // these should now be boolean values, true or false
      mediCalEligibleAges,
      householdSize,
      excludedAges,
      includedAges,
      displayValue,
      isEmployeeMedicareEligible,
      medicareEligible,
      employeeAffordablePercentage,
      familyAffordablePercentage,
    }));

    // Prepare the intake form data object to be saved, which includes form data and any additional data you need
    const intakeFormData = {
      employmentType,
      state,
      zipCode,
      county,
      annualIncome, // this should be a number
      householdSize,
      ageRelationshipPairs,
    };

    // Save form data to local storage
    localStorage.setItem("intakeFormData", JSON.stringify(intakeFormData));

    // Here, you set the intake form data in the context, similar to how you're setting results and additional info.
    setIntakeFormData((prevFormData) => ({
      ...prevFormData,
      ...intakeFormData,
    }));

    // Save the previous post data
    const previousPostData = postData;

    // Create the new post data structure
    let updatedPostData = {
      employeeID: postData.employeeID,
      ...previousPostData,

      ...intakeFormData, // Flatten intakeFormData

      employerCoverageEmployeeOnly: employerCoverageResults.employeeOnly,
      employerCoverageFamily: employerCoverageResults.family,
      ...employerCoverageResults.additionalInfo,

      coveredCAEmployeeOnly: coveredCAResults.employeeOnly,
      coveredCAFamilyIncludingEmployee:
        coveredCAResults.familyIncludingEmployee,
      coveredCAFamilyExcludingEmployee:
        coveredCAResults.familyExcludingEmployee,
      ...coveredCAResults.additionalInfo,

      employeeAffordable,
      familyAffordable,
      medicareEligible,
      mediCalEligibleAges,
      excludedAges,
      includedAges,
      isEmployeeMedicareEligible,
      employeeAffordablePercentage,
      familyAffordablePercentage,
    };
    // 1. Exclude empty arrays
    updatedPostData = Object.fromEntries(
      Object.entries(updatedPostData).filter(
        ([_, v]) => !Array.isArray(v) || v.length > 0
      )
    );

    // 2. Conditionally include data points
    // If household size is 1, there shouldn't be any family data.
    if (updatedPostData.householdSize === "1") {
      delete updatedPostData.employerCoverageFamily;
      delete updatedPostData.coveredCAFamilyIncludingEmployee;
      delete updatedPostData.coveredCAFamilyExcludingEmployee;
      delete updatedPostData.coveredCAFamily;
      // If household size is 1 and employee coverage is affordable, there shouldn't be employee Covered CA data.
      if (updatedPostData.employeeAffordable) {
        delete updatedPostData.coveredCAEmployeeOnly;
      }
    } else {
      // For more than one person in the household.
      if (
        !updatedPostData.employeeAffordable &&
        !updatedPostData.familyAffordable
      ) {
        // Remove employee-only Covered CA cost and cost excluding the employee.
        delete updatedPostData.coveredCAEmployeeOnly;
        delete updatedPostData.coveredCAFamilyExcludingEmployee;
      } else if (
        updatedPostData.employeeAffordable &&
        !updatedPostData.familyAffordable
      ) {
        // Remove cost including the employee for Covered CA.
        delete updatedPostData.coveredCAFamilyIncludingEmployee;
      } else if (
        updatedPostData.employeeAffordable &&
        updatedPostData.familyAffordable
      ) {
        // Remove all costs related to Covered CA.
        delete updatedPostData.coveredCAEmployeeOnly;
        delete updatedPostData.coveredCAFamilyIncludingEmployee;
        delete updatedPostData.coveredCAFamilyExcludingEmployee;
        delete updatedPostData.coveredCAFamily;
      }
    }

    // Ensure you're not merging with the old state
    setPostData(updatedPostData);

    // Use a callback to ensure you're sending the most recent data
    setPostData((prevData) => {
      const newPostData = { ...updatedPostData }; // Create a copy of updatedPostData
      sendPostDataToBackend(newPostData);
      return newPostData; // Return the new state
    });
    console.log("Updated post data:", updatedPostData);

    console.log("intake form data:", intakeFormData);
    navigate("/results");
  };

  const calculateEmployerCoverage = () => {
    console.log("Starting calculation...");

    const calculateCostForAge = (age, relationship) => {
      const contributionRateEntry = contributionMap.find(
        (entry) =>
          entry["Employment Type"] === employmentType &&
          entry["Relationship"] === relationship
      );
      const individualContributionRate = contributionRateEntry
        ? contributionRateEntry["EE Contribution"]
        : null;
      if (!individualContributionRate) {
        console.error(
          `No matching contribution rate found for relationship: ${relationship}`
        );
        return 0;
      }

      const individualAgeEntry = ageFactor.find(
        (entry) =>
          entry["State"] === state && entry["Age"] === parseInt(age, 10)
      );

      const individualAgeFactor = individualAgeEntry
        ? individualAgeEntry["Age Factor"]
        : null;

      if (!individualAgeFactor) {
        console.error(`No matching age factor found for age: ${age}`);
        return 0;
      }

      const ageFactoredIchraPlanCost = ichraPlanCost * individualAgeFactor;

      const akashPlanEntry = akashPlanPegging.find(
        (entry) =>
          entry["STATE"] === state &&
          entry["ZIPCODE"] === parseInt(zipCode, 10) &&
          entry["COUNTY"] === county
      );

      const akashPlanCost = akashPlanEntry ? akashPlanEntry["PLANCOST"] : null;

      if (!akashPlanCost) {
        console.error(
          "No matching akash plan cost found for the given criteria."
        );
        return ageFactoredIchraPlanCost; // return ichra plan cost if akash plan cost isn't found
      }

      const ageFactoredAkashPlanCost = akashPlanCost * individualAgeFactor;

      const employerContributionAkash =
        ageFactoredAkashPlanCost -
        ageFactoredAkashPlanCost * individualContributionRate;

      return ageFactoredIchraPlanCost - employerContributionAkash;
    };

    const matchingEntry = ichraPlan.find(
      (entry) =>
        entry["STATE"] === state &&
        entry["ZIPCODE"] === parseInt(zipCode, 10) &&
        entry["COUNTY"] === county
    );

    const ichraPlanCost = matchingEntry ? matchingEntry["PLANCOST"] : null;
    console.log("ICHRA Rate:", matchingEntry);
    if (!ichraPlanCost) {
      console.error(
        "No matching ichra plan cost found for the given criteria."
      );
      return {};
    }

    let employerFamilyCostExcludingMedicare = 0;
    let medicareFamilyAges = [];
    let nonMedicareFamilyAges = [];
    let isEmployeeMedicareEligible = false; // Initialize with false

    for (let i = 0; i < ageRelationshipPairs.length; i++) {
      const individualAge = parseInt(ageRelationshipPairs[i].age, 10);
      const relationship = ageRelationshipPairs[i].relationship;

      if (individualAge > 64) {
        medicareFamilyAges.push(individualAge);
        if (i === 0) {
          // Check if the employee is Medicare eligible
          isEmployeeMedicareEligible = true;
        }
        continue; // Skip the cost calculation for Medicare eligible individuals
      }
      nonMedicareFamilyAges.push(individualAge);
      employerFamilyCostExcludingMedicare += calculateCostForAge(
        individualAge,
        relationship
      );
    }
    const employerCoverageFamilyCost =
      Math.round(employerFamilyCostExcludingMedicare * 100) / 100;

    let annualEmployeeCoverageCost =
      calculateCostForAge(
        ageRelationshipPairs[0].age,
        ageRelationshipPairs[0].relationship
      ) * 12;
    let employeeAffordablePercentage =
      (annualEmployeeCoverageCost / annualIncome) * 100;
    employeeAffordablePercentage = parseFloat(
      employeeAffordablePercentage.toFixed(2)
    );
    let employeeAffordable = employeeAffordablePercentage <= 8.39;

    let annualFamilyCoverageCost = employerCoverageFamilyCost * 12;
    let familyAffordablePercentage =
      (annualFamilyCoverageCost / annualIncome) * 100;
    familyAffordablePercentage = parseFloat(
      familyAffordablePercentage.toFixed(2)
    );
    let familyAffordable = familyAffordablePercentage <= 8.39;

    const finalResults = {
      employeeOnly: {
        cost: isEmployeeMedicareEligible
          ? 0
          : Math.round(
              calculateCostForAge(
                ageRelationshipPairs[0].age,
                ageRelationshipPairs[0].relationship
              ) * 100
            ) / 100,
        ages: [ageRelationshipPairs[0].age],
      },
      family: {
        cost: employerCoverageFamilyCost || null,
        ages: nonMedicareFamilyAges,
      },
      additionalInfo: {
        employeeAffordable,
        medicareEligible: medicareFamilyAges,
        familyAffordable,
        isEmployeeMedicareEligible,
        employeeAffordablePercentage,
        familyAffordablePercentage,
      },
    };
    console.log("Final results:", finalResults);
    console.log("Medi-Care Family Ages:", medicareFamilyAges);
    console.log("Employee Medicare:", isEmployeeMedicareEligible);
    return finalResults;
  };

  const calculateCoveredCACost = () => {
    const fplThreshold = guidelines.find(
      (entry) => entry["Household Size"] === parseInt(householdSize, 10)
    )["Threshold"];

    // Calculate FPL percentage and round to nearest hundredth
    let FPLPercentage = annualIncome / fplThreshold;
    FPLPercentage = Math.round(FPLPercentage * 100) / 100;

    console.log("FPL Percentage:", FPLPercentage);

    let includedAges = ageRelationshipPairs.map((pair) =>
      parseInt(pair.age, 10)
    );
    let includedRelationships = ageRelationshipPairs.map(
      (pair) => pair.relationship
    );
    let mediCalEligibleAges = [];
    let medicareEligibleAges = [];
    let excludedAges = []; // We'll collect all excluded ages here

    // Define an array for specific counties for the extended FPL check
    const specificCounties = ["San Francisco", "San Mateo", "Santa Clara"];

    // Medi-Cal eligibility check
    if (FPLPercentage <= 1.38) {
      ageRelationshipPairs.forEach((pair) => {
        const age = parseInt(pair.age, 10);
        if (age < 65) {
          mediCalEligibleAges.push(age);
        } else {
          medicareEligibleAges.push(age);
        }
      });
    } else {
      // Specific checks for Medi-Cal eligibility based on age, FPL%, relationship, and county
      ageRelationshipPairs.forEach((pair) => {
        const age = parseInt(pair.age, 10);
        const isChild = pair.relationship === "Child"; // Check for 'Child' relationship
        const isSpecificCounty = specificCounties.includes(county); // Check if the provided county matches the specific counties
        if (age >= 65) {
          medicareEligibleAges.push(age);
        } else if (
          isChild &&
          age >= 0 &&
          age <= 18 &&
          ((FPLPercentage > 1.38 && FPLPercentage <= 2.66) ||
            (FPLPercentage > 1.38 && FPLPercentage <= 3.22 && isSpecificCounty))
        ) {
          mediCalEligibleAges.push(age);
        }
      });
    }

    // Combine all eligible ages for exclusion only once
    excludedAges = [
      ...new Set([...mediCalEligibleAges, ...medicareEligibleAges]),
    ];

    // Remove the Medi-Cal and Medicare eligible ages from includedAges
    includedAges = includedAges.filter((age) => !excludedAges.includes(age));

    // Update includedRelationships to match the updated includedAges
    includedRelationships = includedRelationships.filter(
      (_, index) => !excludedAges.includes(ageRelationshipPairs[index].age)
    );

    // Adjust FPL% to min or max if out of range, after Medi-Cal checks
    const maxFPLPercentage = 7.98;
    const minFPLPercentage = 1.5;
    if (FPLPercentage > maxFPLPercentage) {
      FPLPercentage = maxFPLPercentage;
    } else if (FPLPercentage < minFPLPercentage) {
      FPLPercentage = minFPLPercentage;
    }

    const searchFPL = Number(FPLPercentage.toString());

    let coveredCACostPercentage = fplMapping.find(
      (item) => item["FPL %"] === searchFPL
    )["Cost %"];

    console.log("Cost Percentage:", coveredCACostPercentage);

    let cRate = (annualIncome * coveredCACostPercentage) / 12;
    console.log("C Rate:", cRate);

    const calculateNetCost = (rate, subsidy) => {
      const result = rate - subsidy;
      if (isNaN(result)) {
        console.error(`Unexpected input: rate: ${rate}, subsidy: ${subsidy}`);
        return 0;
      }
      return Math.max(0, Math.round((result + Number.EPSILON) * 100) / 100);
    };

    const actualRates = includedAges.map((age) => {
      const ageInt = parseInt(age, 10);
      const zipInt = parseInt(zipCode, 10);
      const entry = ccaRates.find(
        (e) =>
          e["Age"] === ageInt &&
          e["ZIP Code"] === zipInt &&
          e["County"].toLowerCase() === county.toLowerCase()
      );

      if (!entry) {
        console.log(
          `No match found for age: ${ageInt}, ZIP Code: ${zipInt}, County: ${county}`
        );
      } else {
        console.log(`Match found: `, entry);
      }

      return entry ? entry["Individual Rate"] : 0;
    });

    console.log("Actual costs for each age:", actualRates);

    const finalRates = actualRates.map((rate, index) => {
      const isChildUnder20 =
        ageRelationshipPairs[index] &&
        parseInt(ageRelationshipPairs[index].age) <= 20;
      const childrenUnder20 = ageRelationshipPairs.filter(
        (pair) => parseInt(pair.age) <= 20
      );
      if (
        isChildUnder20 &&
        childrenUnder20.length > 3 &&
        childrenUnder20.indexOf(ageRelationshipPairs[index]) >= 3
      ) {
        return 0;
      }
      return rate;
    });

    console.log("Final costs for each age:", finalRates);

    let EESubsidy = 0;
    let familySubsidy = 0;
    let familyExcludingEESubsidy = 0;

    if (householdSize === "1") {
      const employeeCoveredCAFullRate = actualRates[0];
      EESubsidy = employeeCoveredCAFullRate - cRate;
      console.log(
        `Employee Covered CA Full Rate: ${employeeCoveredCAFullRate}`
      );
    } else {
      const familyCoveredCAFullRate = actualRates.reduce((a, b) => a + b, 0);
      familySubsidy = familyCoveredCAFullRate - cRate;
    }

    if (householdSize !== "1") {
      const familyExcludingEmployeeFullRate = actualRates
        .slice(1)
        .reduce((a, b) => a + b, 0);
      familyExcludingEESubsidy = familyExcludingEmployeeFullRate - cRate;
    }

    const totalFamilyCost = finalRates.reduce((a, b) => a + b, 0);
    const totalFamilyExcludingEECost = finalRates
      .slice(1)
      .reduce((a, b) => a + b, 0);

    const coveredCAEmployeeOnlyCost = calculateNetCost(
      finalRates[0],
      EESubsidy
    );
    const coveredCAFamilyIncludingEmployeeCost = calculateNetCost(
      totalFamilyCost,
      familySubsidy
    );
    const coveredCAFamilyExcludingEmployeeCost = calculateNetCost(
      totalFamilyExcludingEECost,
      familyExcludingEESubsidy
    );

    console.log("Final cost after EE Subsidy:", coveredCAEmployeeOnlyCost);
    console.log(
      "Final cost after Family Subsidy:",
      coveredCAFamilyIncludingEmployeeCost
    );
    console.log(
      "Final cost after Family Excluding EE Subsidy:",
      coveredCAFamilyExcludingEmployeeCost
    );

    console.log(`C Rate (Monthly Contribution based on income): ${cRate}`);
    console.log(`EESubsidy (Supposed to be Full Rate - C Rate): ${EESubsidy}`);

    const finalResults = {
      employeeOnly: {
        cost:
          state !== "CA"
            ? "Non-CA"
            : isNaN(coveredCAEmployeeOnlyCost)
            ? "Error Calculating"
            : coveredCAEmployeeOnlyCost,
        ages: [ageRelationshipPairs[0].age],
      },
      familyIncludingEmployee: {
        cost:
          state !== "CA"
            ? "Non-CA"
            : isNaN(coveredCAFamilyIncludingEmployeeCost)
            ? "Error Calculating"
            : coveredCAFamilyIncludingEmployeeCost,
        ages: includedAges,
      },
      familyExcludingEmployee: {
        cost:
          state !== "CA"
            ? "Non-CA"
            : isNaN(coveredCAFamilyExcludingEmployeeCost)
            ? "Error Calculating"
            : coveredCAFamilyExcludingEmployeeCost,
        ages: includedAges.slice(1),
      },
      additionalInfo: {
        mediCalEligibleAges,
        medicareEligibleAges,
        annualIncome,
        excludedAges,
        includedAges,
      },
    };

    console.log("Final Covered CA Coverage results:", finalResults);
    console.log("EE Subsidy:", EESubsidy);
    console.log("Family Subsidy:", familySubsidy);
    console.log("Family Excluding EE Subsidy:", familyExcludingEESubsidy);
    console.log("medical eligible", mediCalEligibleAges);
    console.log("medicare eligible", medicareEligibleAges);
    console.log("included ages", includedAges);
    console.log("excluded ages", excludedAges);
    return finalResults;
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 5 }}
    >
      <Card
        sx={{
          width: { sm: "75%" },
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 5,
        }}
      >
        <CardContent sx={{ padding: "12px" }}>
          <Box sx={{ mb: 4, typography: "subtitle1", textAlign: "left" }}>
            <span>
              {
                translations[
                  "Tell us a little more about yourself, so we can help you find the right plan."
                ]
              }
            </span>
          </Box>
          <form onSubmit={handleSubmit(performCalculations)}>
            <Controller
              name="employmentType"
              control={control}
              defaultValue={employmentType}
              rules={{ required: "Employment Type is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  variant="outlined"
                  error={!!errors.employmentType}
                  style={{ display: "none" }}
                >
                  <MenuItem value="" disabled>
                    <em>Select Employment Type</em>
                  </MenuItem>
                  <MenuItem value="Full-Time Salary">Full-Time Salary</MenuItem>
                  <MenuItem value="Full-Time Hourly M">
                    Full-Time Hourly M
                  </MenuItem>
                  <MenuItem value="Full-Time Hourly">Full-Time Hourly</MenuItem>
                  <MenuItem value="Part-Time Hourly">Part-Time Hourly</MenuItem>
                </Select>
              )}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel id="state-label">{translations["State"]}</InputLabel>
              <Controller
                name="state"
                control={control}
                defaultValue={state}
                rules={{ required: translations["State is required"] }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="state-label"
                    label={translations["State"]}
                    displayEmpty
                    variant="outlined"
                    error={!!errors.state}
                    disabled={true}
                  >
                    <MenuItem value="AZ">AZ</MenuItem>
                    <MenuItem value="CA">CA</MenuItem>
                    <MenuItem value="MI">MI</MenuItem>
                    <MenuItem value="NV">NV</MenuItem>
                    <MenuItem value="OR">OR</MenuItem>
                    <MenuItem value="WA">WA</MenuItem>
                    <MenuItem value="WI">WI</MenuItem>
                    <MenuItem value="WY">WY</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              name="zipCode"
              control={control}
              defaultValue={zipCode}
              rules={{
                required: translations["Zip Code is required"],
                maxLength: { value: 5, message: "Maximum length is 5" },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Call the default onChange
                    setZipCode(e.target.value); // Update the local state
                    if (e.target.value.length === 5) {
                      populateCounties(state, e.target.value);
                    }
                  }}
                  label={translations["Zip Code"]}
                  variant="outlined"
                  error={!!errors.zipCode}
                  helperText={errors.zipCode ? errors.zipCode.message : null}
                />
              )}
            />
            <FormControl
              fullWidth
              variant="outlined"
              error={!!errors.county || !!countyError}
            >
              <InputLabel id="county-label">
                {translations["County"]}
              </InputLabel>
              <Controller
                name="county"
                control={control}
                defaultValue={availableCounties.length > 0 ? county : ""}
                rules={{ required: translations["County is required"] }}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      labelId="county-label"
                      label={translations["County"]}
                      displayEmpty
                      variant="outlined"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getcontentanchorel: null, // Correct the property name to getcontentanchorel
                      }}
                      onChange={(e) => {
                        field.onChange(e); // Call the default onChange
                        setCounty(e.target.value); // Update the local state
                      }}
                    >
                      {availableCounties.map((c) => (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.county ? errors.county.message : countyError}
                    </FormHelperText>
                  </>
                )}
              />
            </FormControl>

            <Controller
              name="annualIncome"
              control={control}
              defaultValue={annualIncome}
              rules={{
                required: translations["Annual Household Income is required"],
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={displayValue}
                  onChange={(e) => handleInputChange(e, field.onChange)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="Enter an estimated amount of your total annual household income. Enter the gross amount (before taxes are deducted), and be sure to include the income of all household members."
                          arrow
                          placement="bottom"
                          enterTouchDelay={50}
                          leaveTouchDelay={9000}
                          PopperProps={{
                            sx: {
                              [`& .MuiTooltip-tooltip`]: {
                                backgroundColor: "rgba(0, 160, 168, 0.9)",
                              },
                              [`& .MuiTooltip-arrow`]: {
                                "&:before": {
                                  backgroundColor: "rgba(0, 160, 168, 0.9)",
                                },
                              },
                            },
                          }}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  label={translations["Annual Household Income"]}
                  variant="outlined"
                  type="text"
                  placeholder="0"
                  error={!!errors.annualIncome}
                  helperText={
                    errors.annualIncome ? errors.annualIncome.message : null
                  }
                />
              )}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel id="householdSize-label">
                {translations["Household Size"]}
              </InputLabel>
              <Controller
                name="householdSize"
                control={control}
                defaultValue={householdSize}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e); // This is for the Controller to get the value
                      setHouseholdSize(e.target.value); // This updates the state
                    }}
                    labelId="householdSize-label"
                    label={translations["Household Size"]}
                    displayEmpty
                    variant="outlined"
                    error={!!errors.householdSize}
                    menuprops={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getcontentanchorel: null, // This is important to make the menu display below the input
                    }}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <Box sx={{ mt: 2, mb: 2, typography: "body2", textAlign: "left" }}>
              <span>
                {
                  translations[
                    "Enter the AGE of each person in your household as of January 1, 2024 and their RELATIONSHIP to you:"
                  ]
                }
              </span>
            </Box>
            {ageRelationshipPairs.map((pair, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex", // establishes a Flexbox layout
                  flexDirection: "row", // aligns children (the input fields) in a row
                  justifyContent: "space-between", // adds space between the children
                  alignItems: "center", // vertically aligns the children in the container
                }}
              >
                <Controller
                  name={`age_${index}`}
                  control={control}
                  defaultValue={pair.age}
                  rules={{
                    required: translations["Age is required"],
                    validate: (value) => ageValidation(value, index),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        field.onChange(e); // Call the default onChange
                        const updatedPairs = [...ageRelationshipPairs];
                        updatedPairs[index].age = e.target.value;
                        setAgeRelationshipPairs(updatedPairs); // Update the local state
                      }}
                      label={translations["Age"]}
                      variant="outlined"
                      type="number"
                      inputProps={{ min: "0" }}
                      error={!!errors[`age_${index}`]}
                      helperText={
                        errors[`age_${index}`]
                          ? errors[`age_${index}`].message
                          : null
                      }
                      sx={{ marginRight: 2, flex: 1 }} // added right margin and flex for spacing and flexible layout
                      menuprops={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getcontentanchorel: null, // This is important to make the menu display below the input
                      }}
                    />
                  )}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginLeft: 2, // adds some margin on the left for spacing
                    flex: 1, // makes sure it takes up only the necessary space
                  }}
                >
                  <InputLabel id={`relationship-label-${index}`}>
                    {translations["Relationship"]}
                  </InputLabel>
                  <Controller
                    name={`relationship_${index}`}
                    control={control}
                    defaultValue={pair.relationship}
                    rules={relationshipValidation}
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={(e) => {
                          field.onChange(e); // Call the default onChange
                          const updatedPairs = [...ageRelationshipPairs];
                          updatedPairs[index].relationship = e.target.value;
                          setAgeRelationshipPairs(updatedPairs); // Update the local state
                        }}
                        labelId={`relationship-label-${index}`}
                        label={translations["Relationship"]}
                        displayEmpty
                        variant="outlined"
                        error={!!errors[`relationship_${index}`]}
                        menuprops={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getcontentanchorel: null, // This is important to make the menu display below the input
                        }}
                      >
                        {index === 0 && (
                          <MenuItem value="Employee">
                            {translations["Self"]}
                          </MenuItem>
                        )}
                        {index !== 0 &&
                          !ageRelationshipPairs.some(
                            (p, idx) =>
                              idx !== index && p.relationship === "Spouse"
                          ) && (
                            <MenuItem value="Spouse">
                              {translations["Spouse/Partner"]}
                            </MenuItem>
                          )}
                        {index !== 0 && (
                          <MenuItem value="Child">
                            {translations["Child"]}
                          </MenuItem>
                        )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
            ))}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3, textTransform: "none" }}
            >
              {translations["Submit"]}
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}

export default IntakeForm;
