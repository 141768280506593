// src/contexts/LanguageContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en"); // default is English
  const [translations, setTranslations] = useState({});

  const switchLanguage = (lang) => {
    console.log("Switching language to: ", lang);
    setLanguage(lang);
    // Here we load the translation file dynamically based on language selection
    import(`../locales/${lang}/translation.json`)
      .then((translations) => {
        setTranslations(
          translations.default ? translations.default : translations
        );
      })
      .catch((error) => {
        console.error("Error loading language file: ", error);
      });
  };

  // Load English by default
  useEffect(() => {
    switchLanguage("en");
  }, []);

  return (
    <LanguageContext.Provider
      value={{ language, switchLanguage, translations }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to quickly use the context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
