import React, { useContext, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { DataContext } from "../contexts/dataContexts"; // Update the import path as needed
import { useLanguage } from "../contexts/LanguageContext";
function ResultsEESection() {
  const theme = useTheme();
  const { translations } = useLanguage();
  const { results, additionalInfo, intakeFormData } = useContext(DataContext);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formatCost = (cost) => {
    if (
      cost === "Not-Eligible" ||
      cost === null ||
      cost === undefined ||
      isNaN(cost)
    ) {
      return "Not Eligible";
    }
    return `$${(Math.round(cost * 100) / 100).toFixed(2)}`;
  };

  const { employerCoverageEmployeeOnly, coveredCAEmployeeOnly } = results;
  const {
    mediCalEligibleAges,
    employeeAffordable,
    householdSize,
    medicareEligibleAges,
  } = additionalInfo;
  const state = intakeFormData?.state?.toUpperCase();

  const showStateSubsidized =
    state !== "CA" && householdSize === "1" && !employeeAffordable;
  const isEmployeeOver64 = employerCoverageEmployeeOnly.ages.some(
    (age) => age > 64
  );
  const showCoveredCAOrState = !employeeAffordable && householdSize === "1";
  const showMediCal =
    state === "CA" &&
    mediCalEligibleAges?.length > 0 &&
    !employeeAffordable &&
    householdSize === "1";
  const showMedicare =
    state === "CA" && isEmployeeOver64 && !employeeAffordable;
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ fontSize: theme.typography.pxToRem(25) }} />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ fontWeight: theme.typography.fontWeightMedium }}>
            {translations["Employee-Only Coverage"]}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={theme.spacing(3)}
          >
            {/* Employer Coverage Card */}
            <Card
              variant="outlined"
              sx={{ width: "100%", mb: theme.spacing(2) }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: theme.typography.fontWeightMedium }}
                  >
                    {translations["Akash Corporate Health Benefits"]}
                  </Typography>
                  <Tooltip
                    title={
                      translations[
                        "This option only includes coverage for yourself, not your dependents, and is based on the lowest cost silver plan in your area."
                      ]
                    }
                    placement="bottom"
                    enterTouchDelay={50}
                    leaveTouchDelay={9000}
                    PopperProps={{
                      sx: {
                        [`& .MuiTooltip-tooltip`]: {
                          backgroundColor: "rgba(0, 160, 168, 0.9)", // Adjust the color to your preference
                        },
                        [`& .MuiTooltip-arrow`]: {
                          "&:before": {
                            backgroundColor: "rgba(0, 160, 168, 0.9)", // This ensures the arrow's color matches the bubble
                          },
                        },
                      },
                    }}
                  >
                    <IconButton size="small">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2" color="textSecondary">
                  {
                    translations[
                      "Cost shown is what will be deducted from your paycheck pre-tax each month after Akash’s contribution."
                    ]
                  }
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  <strong>{translations["Ages covered:"]}</strong>{" "}
                  {employerCoverageEmployeeOnly.ages.join(", ")}
                </Typography>
                <Typography variant="body2">
                  <strong>{translations["Monthly cost:"]}</strong>{" "}
                  {formatCost(employerCoverageEmployeeOnly.cost)}
                </Typography>
              </CardContent>
            </Card>

            {/* Medi-Cal or Medicare or Covered CA or State Subsidized Card */}
            {(showMediCal ||
              showMedicare ||
              showCoveredCAOrState ||
              showStateSubsidized) && (
              <Card
                variant="outlined"
                sx={{ width: "100%", mb: theme.spacing(2) }}
              >
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: theme.typography.fontWeightMedium }}
                    >
                      {showMediCal && "Covered California"}
                      {showMedicare && "Covered California"}
                      {showCoveredCAOrState && "Covered California"}
                      {showStateSubsidized &&
                        translations["State Coverage Exchange Benefits"]}
                    </Typography>
                    <Tooltip
                      title={
                        showMediCal
                          ? translations[
                              "This option only includes coverage for yourself, not your dependents."
                            ]
                          : showMedicare
                          ? translations[
                              "This option only includes coverage for yourself, not your dependents."
                            ]
                          : showCoveredCAOrState
                          ? translations[
                              "This option only includes coverage for yourself, not your dependents, and is based on the second lowest-cost silver plan in your area."
                            ]
                          : "This option only includes coverage for yourself, not your dependents."
                      }
                      placement="bottom"
                      enterTouchDelay={50}
                      leaveTouchDelay={9000}
                      PopperProps={{
                        sx: {
                          [`& .MuiTooltip-tooltip`]: {
                            backgroundColor: "rgba(0, 160, 168, 0.9)", // Adjust the color to your preference
                          },
                          [`& .MuiTooltip-arrow`]: {
                            "&:before": {
                              backgroundColor: "rgba(0, 160, 168, 0.9)", // This ensures the arrow's color matches the bubble
                            },
                          },
                        },
                      }}
                    >
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    {showMediCal &&
                      translations[
                        "Based on the information provided, you may qualify for free or low-cost coverage through Medi-Cal."
                      ]}
                    {showMedicare &&
                      translations[
                        "As you are over 64, you may qualify for Medicare."
                      ]}
                    {showCoveredCAOrState &&
                      translations[
                        "Cost shown is what you will owe each month after any government subsidies have been applied."
                      ]}
                    {showStateSubsidized &&
                      translations[
                        "You may qualify for a state-subsidized plan based on your income and household size."
                      ]}
                  </Typography>
                  {!showMediCal && !showMedicare && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      <strong>{translations["Ages covered:"]}</strong>{" "}
                      {coveredCAEmployeeOnly.ages.join(", ")}
                    </Typography>
                  )}
                  {!showMediCal && !showMedicare && (
                    <Typography variant="body2">
                      <strong>{translations["Monthly cost:"]}</strong>{" "}
                      {formatCost(coveredCAEmployeeOnly.cost)}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* Repeat for other sections as needed */}
    </React.Fragment>
  );
}

export default ResultsEESection;
