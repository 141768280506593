import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import EditIcon from "@mui/icons-material/Edit";
import WaiveIcon from "@mui/icons-material/Cancel";
import { useLanguage } from "../contexts/LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";
import WaiveReasonModal from "./WaiveReasonModal";
function Header() {
  const { language, switchLanguage } = useLanguage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [bottomNavValue, setBottomNavValue] = useState(0); // Initialize the bottomNavValue state
  const [isWaiveModalOpen, setIsWaiveModalOpen] = useState(false); // State to control the WaiveReasonModal visibility
  const location = useLocation(); // Hook to get the current route
  const navigate = useNavigate(); // Hook to handle navigation
  const { translations } = useLanguage();
  useEffect(() => {
    console.log("Current language is: ", language);
  }, [language]);

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage !== null) {
      switchLanguage(newLanguage);
    }
  };

  // Open WaiveReasonModal if on specific routes
  const onWaiveClick = () => {
    if (location.pathname === "/intake" || location.pathname === "/results") {
      setIsWaiveModalOpen(true);
    }
  };

  // Close WaiveReasonModal and redirect to the confirmation page
  const handleModalClose = () => {
    setIsWaiveModalOpen(false);
    navigate("/confirmation");
  };

  // Determine if the waive icon should be shown
  const showWaiveIcon =
    location.pathname === "/intake" || location.pathname === "/results";

  return (
    <>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
      >
        <Toolbar>
          <a
            href="https://akash.surecohealth.com/"
            style={{
              display: "inline-block",
              width: isMobile ? "20%" : "133px",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/sureco_akash.png"
              alt="SureCo Akash logo"
              style={{ width: "100%", height: "auto", padding: "0 20px 0 0" }}
            />
          </a>

          <Typography
            variant="body2"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: isMobile ? "0.7rem" : "1rem",
              mr: 2,
            }}
          >
            <strong>{translations["For Assistance Call:"]} </strong>
            <a
              href="tel:+19493295880"
              style={{ color: "#00A0A8", textDecoration: "none" }}
            >
              (949) 329-5880
            </a>
          </Typography>

          <ToggleButtonGroup
            value={language}
            exclusive
            onChange={handleLanguageChange}
            size="small"
            sx={{
              "& .MuiToggleButton-root": {
                fontSize: "0.7rem",
                textTransform: "none",
                "&.Mui-selected": {
                  color: "common.white",
                  backgroundColor: "#00A0A8",
                  "&:hover": {
                    backgroundColor: "#007D85",
                  },
                },
              },
            }}
          >
            <ToggleButton value="en" aria-label="English">
              {isMobile ? "EN" : "English"}
            </ToggleButton>
            <ToggleButton value="es" aria-label="Spanish">
              {isMobile ? "ES" : "Español"}
            </ToggleButton>
          </ToggleButtonGroup>
        </Toolbar>
        <Divider sx={{ my: 0 }} />
      </AppBar>
      <BottomNavigation
        value={bottomNavValue}
        onChange={(event, newValue) => {
          setBottomNavValue(newValue);
        }}
        showLabels
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <BottomNavigationAction
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
          label={translations["Home"]}
          icon={<HomeIcon />}
          onClick={() => {
            window.location.href = "https://akash.surecohealth.com/";
          }}
        />
        {/* Conditional BottomNavigationAction for Edit Info option */}
        {location.pathname === "/results" && (
          <BottomNavigationAction
            label={translations["Edit Info"]}
            icon={<EditIcon />}
            onClick={() => navigate("/intake")} // This navigates back to the intake page
          />
        )}
        <BottomNavigationAction
          label={translations["Call"]}
          icon={<PhoneIcon />}
          href="tel:+19493295880"
        />
        {/* Conditional BottomNavigationAction for Waive option */}
        {(location.pathname === "/intake" ||
          location.pathname === "/results") && (
          <BottomNavigationAction
            label={translations["Waive"]}
            icon={<WaiveIcon />}
            onClick={() => setIsWaiveModalOpen(true)} // This opens the WaiveReasonModal
          />
        )}
      </BottomNavigation>
      <WaiveReasonModal
        open={isWaiveModalOpen}
        onClose={() => setIsWaiveModalOpen(false)}
        onSaveReason={handleModalClose}
      />
    </>
  );
}

export default Header;
