import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#FFFFFF", // white text
          backgroundColor: "#003146", // blue background
          "&:hover": {
            backgroundColor: "#00A0A8", // dark blue background on hover
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: "#F9F4EC",
          backgroundColor: "#F9F4EC",
          borderRadius: "10px",
        },
      },
    },
    MuiTextField: {
      // styles for text field
      styleOverrides: {
        root: {
          backgroundColor: "white",
          // additional styles can be added here if needed
        },
      },
    },
    MuiSelect: {
      // styles for select
      styleOverrides: {
        select: {
          backgroundColor: "white",
        },
      },
    },
    MuiInputLabel: {
      // styles for input label
      styleOverrides: {
        root: {
          fontSize: "0.75rem", // change this to your desired size
          textAlign: "center", // centers the text
        },
      },
    },
    MuiInput: {
      // styles for input
      styleOverrides: {
        input: {
          fontSize: "0.80rem", // change this to your desired size
        },
      },
    },
    MuiMenuItem: {
      // styles for menu item
      styleOverrides: {
        root: {
          fontSize: "0.80rem", // change this to your desired size
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#003146", // The color you want for all typography
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          color: "#00A0A8", // Sets the size for all SvgIcons, adjust as needed
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9F4EC",
          margin: "0px", // your container's color
        },
        contained: {
          // styles specific to error state, if necessary
          color: "red", // example of changing text color for errors
          // ... other styles for error state ...
        },
      },
    },
  },
});

export default theme;
