import React, { useContext } from "react";
import { DataContext } from "../contexts/dataContexts";
import { Box, Typography, Divider } from "@mui/material";
import ResultsEESection from "./ResultsEESection";
import ResultsFamilySection from "./ResultsFamilySection";
import ResultsNextSteps from "./ResultsNextSteps";
import { useLanguage } from "../contexts/LanguageContext";
function Results() {
  const { results, additionalInfo } = useContext(DataContext);
  const { translations } = useLanguage();
  // Helper function to format cost or handle non-numeric values
  const formatCost = (cost) => {
    if (
      cost === "Not-Eligible" ||
      cost === null ||
      cost === undefined ||
      isNaN(cost)
    ) {
      return "Not-Eligible"; // or return cost if you want to display "not-eligible"
    }
    // Ensure the cost is rounded to two decimal places here
    return `$${(Math.round(cost * 100) / 100).toFixed(2)}`; // If it's a valid number, format as currency with two decimal places
  };

  const {
    employerCoverageEmployeeOnly,
    coveredCAEmployeeOnly,
    employerCoverageFamily,
    coveredCAFamilyIncludingEmployee,
    coveredCAFamilyExcludingEmployee,
  } = results;

  const {
    mediCalEligibleAges,
    employeeAffordable,
    familyAffordable,
    householdSize,
    medicareFamilyAges,
    excludedAges,
    includedAges,
  } = additionalInfo;

  // Conditional variables
  const showFamilySection = householdSize !== "1";
  const isEmployeeCoveredCANotEligible =
    householdSize !== "1" || employeeAffordable;
  const isFamilyCoveredCANotEligible =
    householdSize !== "1" && familyAffordable;
  // Conditional check for Medi-Cal eligibility
  const isMediCalEligible =
    mediCalEligibleAges && mediCalEligibleAges.length > 0;

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {/* Header */}
      <Typography variant="h5" sx={{ fontWeight: 600, mt: 2, mb: 2 }}>
        {translations["Your Health Benefit Options"]}
      </Typography>

      {/* Remove Divider if you want a cleaner look */}
      <Divider sx={{ my: 2, mx: "0.8rem", borderColor: "#e0e0e0" }} />

      {/* Subhead */}
      <Typography variant="subtitle2" sx={{ mb: 4, color: "#757575" }}>
        {
          translations[
            "Explore the monthly costs for the benefits available to you and any eligible dependents, then enroll in your preferred plan."
          ]
        }
      </Typography>

      {/* Employee Section */}
      <Box sx={{ mb: 2 }}>
        {" "}
        {/* Adjust bottom margin as needed */}
        <ResultsEESection />
      </Box>

      {/* Family Section */}
      <Box sx={{ mb: 2 }}>
        {" "}
        <ResultsFamilySection />
      </Box>

      {/* Next Steps Section */}
      <Box sx={{ mb: 5 }}>
        {" "}
        {/* Adjust bottom margin as needed */}
        <ResultsNextSteps showFamilySection={showFamilySection} />
      </Box>
    </Box>
  );
}

export default Results;
