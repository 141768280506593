import React, { useContext, useState } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { DataContext } from "../contexts/dataContexts";
import { useLanguage } from "../contexts/LanguageContext";

function ResultsFamilySection() {
  const theme = useTheme();
  const { translations } = useLanguage();
  const { results, additionalInfo, intakeFormData } = useContext(DataContext);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formatCost = (cost) => {
    if (cost === "Not-Eligible" || cost === null || isNaN(cost)) {
      return "Not Eligible";
    }
    return `$${(Math.round(cost * 100) / 100).toFixed(2)}`;
  };

  const {
    employerCoverageFamily,
    coveredCAFamilyIncludingEmployee,
    coveredCAFamilyExcludingEmployee,
  } = results;

  const {
    includedAges,
    mediCalEligibleAges,
    medicareEligibleAges,
    employeeAffordable,
    familyAffordable,
    householdSize,
    isEmployeeMedicareEligible,
  } = additionalInfo;

  const showFamilySection = householdSize !== "1";
  const state = intakeFormData?.state?.toUpperCase();
  const isCAState = state === "CA";
  return (
    <>
      {showFamilySection && (
        <Accordion
          expanded={expanded === "panelFamily"}
          onChange={handleAccordionChange("panelFamily")}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: theme.typography.pxToRem(25) }} />
            }
            aria-controls="panelFamily-content"
            id="panelFamily-header"
          >
            <Typography sx={{ fontWeight: theme.typography.fontWeightMedium }}>
              {translations["Employee + Family Coverage"]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
              {/* Employer Family Coverage */}
              {!isEmployeeMedicareEligible && householdSize !== "1" && (
                <FamilyCoverageCard
                  title={translations["Akash Corporate Health Benefits"]}
                  tooltip={
                    translations[
                      "This option includes coverage for yourself and any eligible dependents. It is based on the lowest-cost silver plan in your area."
                    ]
                  }
                  description={
                    translations[
                      "Cost shown is for yourself and any eligible dependents. It will be deducted from your paycheck pre-tax each month after Akash’s contribution."
                    ]
                  }
                  ages={employerCoverageFamily.ages}
                  cost={formatCost(employerCoverageFamily.cost)}
                  medicareAges={medicareEligibleAges}
                />
              )}

              {/* Family Employer Coverage (EE Medicare) */}
              {isEmployeeMedicareEligible && householdSize !== "1" && (
                <FamilyCoverageCard
                  title={translations["Akash Corporate Health Benefits"]}
                  tooltip={
                    translations[
                      "This option includes coverage for your eligible dependents only. To find out how much coverage for yourself will be, elect “Medicare” in the Enrollment Platform and a SureCo Employee Experience Specialist will call you to complete the process."
                    ]
                  }
                  description={
                    translations[
                      "You may qualify for an Akash reimbursement of a portion of your Medicare premiums. The cost shown is what you will owe each month for any eligible dependent(s) and does not include your own coverage. It will be deducted from your paycheck pre-tax each month after Akash’s contribution."
                    ]
                  }
                  ages={employerCoverageFamily.ages}
                  cost={formatCost(employerCoverageFamily.cost)}
                  medicareAges={medicareEligibleAges}
                />
              )}

              {/* Covered CA Family (including employee) */}
              {!employeeAffordable &&
                !familyAffordable &&
                householdSize !== "1" &&
                includedAges &&
                includedAges.length > 0 &&
                isCAState && (
                  <FamilyCoverageCard
                    title="Covered California"
                    tooltip={
                      translations[
                        "This option includes coverage for yourself and any eligible dependents. It is based on the second lowest-cost silver plan in your area."
                      ]
                    }
                    description={
                      translations[
                        "Cost shown is what you will owe each month for yourself and any eligible dependents. It includes any government subsidies."
                      ]
                    }
                    ages={includedAges}
                    cost={formatCost(coveredCAFamilyIncludingEmployee.cost)}
                    mediCalEligibleAges={mediCalEligibleAges}
                    medicareEligibleAges={medicareEligibleAges}
                  />
                )}

              {/* Covered CA Family (excluding employee) */}
              {employeeAffordable &&
                !familyAffordable &&
                householdSize !== "1" &&
                includedAges &&
                includedAges.length > 0 &&
                isCAState && (
                  <FamilyCoverageCard
                    title="Covered California"
                    tooltip={
                      translations[
                        "This option includes coverage for your eligible dependents only. The cost shown is after any government subsidies have been applied and is based on the second lowest-cost silver plan in your area."
                      ]
                    }
                    description={
                      translations[
                        "Cost shown is what you will owe each month for any eligible dependents and does not include your own coverage."
                      ]
                    }
                    ages={coveredCAFamilyExcludingEmployee.ages}
                    cost={formatCost(coveredCAFamilyExcludingEmployee.cost)}
                    mediCalEligibleAges={mediCalEligibleAges} // Add this prop
                    medicareEligibleAges={medicareEligibleAges}
                  />
                )}

              {/* Covered CA full family medi-cal */}
              {!employeeAffordable &&
                !familyAffordable &&
                householdSize !== "1" &&
                (!includedAges || includedAges.length === 0) &&
                mediCalEligibleAges &&
                mediCalEligibleAges.length > 0 &&
                (!medicareEligibleAges || medicareEligibleAges.length === 0) &&
                isCAState && (
                  <FamilyCoverageCard
                    title="Covered California"
                    tooltip={
                      translations[
                        "All individuals listed may qualify for Medi-Cal."
                      ]
                    }
                    description={
                      translations[
                        "All individuals listed may qualify for free or low-cost coverage through Medi-Cal."
                      ]
                    }
                    // No ages displayed since all are eligible for Medi-Cal
                  />
                )}

              {/* Covered CA (medicare/medi-cal) */}
              {!employeeAffordable &&
                !familyAffordable &&
                householdSize !== "1" &&
                (!includedAges || includedAges.length === 0) &&
                mediCalEligibleAges &&
                mediCalEligibleAges.length > 0 &&
                medicareEligibleAges &&
                medicareEligibleAges.length > 0 &&
                isCAState && ( // Check that medicareEligibleAges has elements
                  <FamilyCoverageCard
                    title="Covered California"
                    tooltip={
                      translations[
                        "This option includes coverage for yourself and any eligible dependents. The below shows who is eligible for Medi-Cal and/or Medicare"
                      ]
                    }
                    description={
                      translations[
                        "The below shows who is eligible for Medi-Cal and/or Medicare."
                      ]
                    }
                    mediCalEligibleAges={mediCalEligibleAges}
                    medicareEligibleAges={medicareEligibleAges}
                    cost="N/A"
                  />
                )}

              {/* State Subsidized Qualify */}
              {!familyAffordable && householdSize !== "1" && !isCAState && (
                <FamilyCoverageCard
                  title={translations["State Coverage Exchange Benefits"]}
                  tooltip={
                    translations[
                      "This option includes coverage for yourself and/or any eligible dependents."
                    ]
                  }
                  description={
                    translations[
                      "You may qualify for a state-subsidized plan. See Next Steps section below for more details."
                    ]
                  }
                  ages={[]}
                  cost={translations["Varies"]}
                />
              )}

              {/* State Subsidized Dont Qualify */}
              {familyAffordable && householdSize !== "1" && !isCAState && (
                <FamilyCoverageCard
                  title={translations["State Coverage Exchange Benefits"]}
                  tooltip={
                    translations[
                      "This option includes coverage for yourself and/or any eligible dependents."
                    ]
                  }
                  description={
                    translations[
                      "You do not qualify for alternative state options at this time. If you feel this may be an error please contact us at (949) 329-5880."
                    ]
                  }
                  ages={[]}
                  cost={translations["Not Eligible"]}
                />
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

function FamilyCoverageCard({
  title,
  tooltip,
  description,
  ages,
  cost,
  medicareEligibleAges,
  mediCalEligibleAges,
}) {
  const theme = useTheme();
  const { translations } = useLanguage();

  return (
    <Card variant="outlined" sx={{ width: "100%", mb: theme.spacing(2) }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {title}
          </Typography>
          <Tooltip
            title={tooltip}
            arrow
            placement="bottom"
            enterTouchDelay={50}
            leaveTouchDelay={9000}
            PopperProps={{
              sx: {
                [`& .MuiTooltip-tooltip`]: {
                  backgroundColor: "rgba(0, 160, 168, 0.9)", // Adjust the color to your preference
                },
                [`& .MuiTooltip-arrow`]: {
                  "&:before": {
                    backgroundColor: "rgba(0, 160, 168, 0.9)", // This ensures the arrow's color matches the bubble
                  },
                },
              },
            }}
          >
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
        {ages && ages.length > 0 && (
          <Typography variant="body2" sx={{ mt: 2 }}>
            <strong>{translations["Ages covered:"]}</strong> {ages.join(", ")}
          </Typography>
        )}
        {cost && (
          <Typography variant="body2">
            <strong>{translations["Monthly cost:"]}</strong> {cost}
          </Typography>
        )}
        {mediCalEligibleAges != null && mediCalEligibleAges.length > 0 && (
          <Typography variant="body2">
            <strong>{translations["Medi-Cal Eligible Ages:"]}</strong>{" "}
            {mediCalEligibleAges.join(", ")}
          </Typography>
        )}
        {medicareEligibleAges != null && medicareEligibleAges.length > 0 && (
          <Typography variant="body2">
            <strong>{translations["Medicare Eligible Ages:"]}</strong>{" "}
            {medicareEligibleAges.join(", ")}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default ResultsFamilySection;
