import React, { useEffect, useContext } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../contexts/dataContexts"; // Update the path to your DataContext file
import { useLanguage } from "../contexts/LanguageContext";
function ConfirmationScreen() {
  const navigate = useNavigate();
  const { postData, setPostData } = useContext(DataContext);
  const { translations } = useLanguage();
  const sendPostDataToBackend = async (data) => {
    try {
      const response = await fetch(
        "https://m4u8nxs6t1.execute-api.us-west-2.amazonaws.com/dev/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Failed to save data.");
      }

      console.log("Data saved successfully:", responseData);
    } catch (error) {
      console.error("Error:", error.message);
      alert("Failed to save data. Please try again.");
    }
  };
  // Function to get the user's IP address
  const fetchIPAddress = async () => {
    try {
      // Replace with the API endpoint you're using
      const ipResponse = await fetch("https://api.ipify.org?format=json");
      const ipData = await ipResponse.json();
      return ipData.ip;
    } catch (error) {
      console.error("Could not get IP address:", error);
      return null;
    }
  };

  useEffect(() => {
    const handleDataCapture = async () => {
      const ip = await fetchIPAddress();

      const updatedData = {
        ...postData,
        status: "completed",
        completedWaiverTime: new Date().toISOString(),
        userIP: ip,
      };

      setPostData(updatedData);
      sendPostDataToBackend(updatedData);
    };

    handleDataCapture();
  }, []);

  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <Box sx={{ mt: 8, mb: 4, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          {translations["Thank you!"]}
        </Typography>
      </Box>
      <Box sx={{ mt: 4, mb: 8, textAlign: "left" }}>
        <Typography variant="h6" gutterBottom>
          {
            translations[
              "Your decision to waive the Akash corporate health insurance plan has been successfully completed."
            ]
          }
        </Typography>
      </Box>
      <Box sx={{ mt: 4, mb: 4, textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          {translations["Here's a breakdown of what you've acknowledged:"]}
        </Typography>
      </Box>
      <Box sx={{ mb: 6, textAlign: "left" }}>
        <Typography variant="subtitle1" gutterBottom>
          <strong>
            {translations["Flexibility Until November 30, 2023:"]}
          </strong>
        </Typography>
        <Box
          component="ul"
          sx={{ m: 0, p: 0, "& li": { listStyleType: "disc", ml: 2, mt: 1 } }}
        >
          <li>
            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              {
                translations[
                  "You can still choose alternative plans using SureCo’s state plan election tool until November 30, 2023."
                ]
              }
            </Typography>
          </li>
          <li>
            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              {
                translations[
                  "After this date, this option won't be available, so be sure to make any changes before then."
                ]
              }
            </Typography>
          </li>
        </Box>
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 4 }}>
          <strong> {translations["Future Enrollment Conditions:"]} </strong>
        </Typography>
        <Box
          component="ul"
          sx={{ m: 0, p: 0, "& li": { listStyleType: "disc", ml: 2, mt: 1 } }}
        >
          <li>
            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              {
                translations[
                  "Going forward, you can enroll during specific open enrollment periods."
                ]
              }
            </Typography>
          </li>
          <li>
            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              {
                translations[
                  "Alternatively, if you qualify for a Special Enrollment Period, you'll have the opportunity to sign up."
                ]
              }
            </Typography>
          </li>
        </Box>
      </Box>
      <Box sx={{ my: 4, mb: 10, textAlign: "center" }}>
        <Typography variant="subtitle1" component="p">
          <strong>
            {
              translations[
                "If you have any questions or need further assistance, please reach out."
              ]
            }
          </strong>
        </Typography>
        <Typography variant="subtitle1" component="p">
          <strong>(949) 329-5880</strong>
        </Typography>
      </Box>
    </Container>
  );
}

export default ConfirmationScreen;
