import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/themes"; // adjusted to match the file name and location
import AuthenticationScreen from "./components/AuthenticationScreen";
import DisclosureScreen from "./components/DisclosureScreen";
import IntakeForm from "./components/IntakeForm";
import Results from "./components/Results";
import { DataProvider } from "./contexts/dataContexts";
import DisclosureConfirmationScreen from "./components/DisclosureConfirmationScreen";
import Header from "./components/Header";
import { LanguageProvider } from "./contexts/LanguageContext"; // import the LanguageProvider

function App() {
  return (
    <LanguageProvider>
      <DataProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<AuthenticationScreen />} />
              <Route path="/disclosure" element={<DisclosureScreen />} />
              <Route path="/intake" element={<IntakeForm />} />
              <Route path="/results" element={<Results />} />
              <Route
                path="/confirmation"
                element={<DisclosureConfirmationScreen />}
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </DataProvider>
    </LanguageProvider>
  );
}

export default App;
