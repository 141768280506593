/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import idMappingData from "../assets/jsons/ID_mapping_modified.json"; // Adjust the path as needed
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { DataContext } from "../contexts/dataContexts";
import { useLanguage } from "../contexts/LanguageContext";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Container,
  Card,
  CardContent,
  Box,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "../styles/AuthenticationScreen.module.css";
function AuthenticationScreen({ handleMatchingEmployee }) {
  const navigate = useNavigate();
  const { setEmployeeData, setUtmData, utmData, setPostData, postData } =
    useContext(DataContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { translations } = useLanguage();
  const employeeID = null;

  //handle error dialog for no match found
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Function to capture and store utm_source from URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source");
    if (utmSource) {
      setUtmData(utmSource);
    }
  }, [setUtmData]);

  const normalizeInput = (name, value) => {
    switch (name) {
      case "lastInitial":
        return value.toUpperCase().slice(0, 1);
      case "firstName":
        return value.charAt(0).toUpperCase() + value.slice(1);
      case "dob":
        const dob = value.replace(/\D/g, "").slice(0, 8); // Ensure only numbers and max 8 characters
        if (dob.length === 8) {
          return `${dob.slice(0, 2)}-${dob.slice(2, 4)}-${dob.slice(4, 8)}`;
        }
        return dob;
      case "mobilePhone":
        const phone = value.replace(/\D/g, "").slice(0, 10); // Ensure only numbers and max 10 characters
        if (phone.length === 10) {
          return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
            6,
            10
          )}`;
        }
        return phone;
      default:
        return value;
    }
  };

  useEffect(() => {
    // This code runs when the component is mounted, which happens when the application starts
    localStorage.clear();
  }, []);

  const [householdSize, setHouseholdSize] = useState("");

  const onSubmit = async (data) => {
    const matchingEmployee = idMappingData.find(
      (employee) =>
        employee["First Name"].toLowerCase() ===
          data.firstName.trim().toLowerCase() &&
        employee["Last Initial"].toLowerCase() ===
          data.lastInitial.toLowerCase() &&
        employee["DOB"] === data.dob &&
        employee["State"] === data.state &&
        employee["Zip Code"] === parseInt(data.zipCode)
    );

    if (matchingEmployee) {
      setEmployeeData({
        employeeID: matchingEmployee["Employee ID"],
        employmentType: matchingEmployee["Employment Type"],
        firstName: matchingEmployee["First Name"],
        lastName: matchingEmployee["Last Name"],
        state: matchingEmployee["State"],
        zipCode: matchingEmployee["Zip Code"],
        householdSize: householdSize,
      });

      // Prepare the data to send
      const postData = {
        employeeID: matchingEmployee["Employee ID"], // Unique identifier
        employmentType: matchingEmployee["Employment Type"],
        firstName: matchingEmployee["First Name"],
        lastName: matchingEmployee["Last Name"],
        dob: data.dob,
        state: data.state,
        zipCode: data.zipCode,
        mobilePhone: data.mobilePhone,
        householdSize: data.householdSize,
        utmData: utmData || null, // Assuming utmData is stored in a state or context
      };

      // Save postData to the context
      setPostData(postData);
      // Send data to the backend
      try {
        const response = await fetch(
          "https://m4u8nxs6t1.execute-api.us-west-2.amazonaws.com/dev/", // your API endpoint
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        );

        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData.message || "Failed to save data.");
        }

        console.log("Data saved successfully:", responseData);
        // Proceed with navigating to the next screen or whatever your app does next
        navigate("/disclosure");
      } catch (error) {
        console.error("Error:", error.message);
        // Handle errors, like showing an error message to the user
      }
    } else {
      handleOpenDialog();
    }
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: 3,
        mb: 5,
      }}
    >
      <Card
        sx={{
          width: { sm: "75%" },
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 5,
        }}
      >
        <CardContent sx={{ padding: "12px" }}>
          <Box sx={{ mb: 1, typography: "h5", textAlign: "left" }}>
            {translations["Welcome to Akash Health Benefits"]}
          </Box>
          <Box
            sx={{ mt: 0, mb: 4, typography: "subtitle1", textAlign: "left" }}
          >
            {translations["Help us confirm your information."]}
          </Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{ required: translations["First Name is required"] }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(
                      normalizeInput(e.target.name, e.target.value)
                    );
                  }}
                  label={translations["First Name"]}
                  variant="outlined"
                  error={!!errors.firstName}
                  helperText={
                    errors.firstName ? errors.firstName.message : null
                  }
                />
              )}
            />

            <Controller
              name="lastInitial"
              control={control}
              defaultValue=""
              rules={{
                required: translations["Last Initial is required"],
                maxLength: { value: 1, message: "Maximum length is 1" },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(
                      normalizeInput(e.target.name, e.target.value)
                    );
                  }}
                  label={translations["Last Initial"]}
                  variant="outlined"
                  error={!!errors.lastInitial}
                  helperText={
                    errors.lastInitial ? errors.lastInitial.message : null
                  }
                />
              )}
            />

            <Controller
              name="dob"
              control={control}
              defaultValue=""
              rules={{ required: translations["Date of Birth is required"] }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(
                      normalizeInput(e.target.name, e.target.value)
                    );
                  }}
                  label={translations["Date of Birth"]}
                  variant="outlined"
                  placeholder="mm-dd-yyyy"
                  error={!!errors.dob}
                  helperText={errors.dob ? errors.dob.message : null}
                />
              )}
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel id="state-label">{translations["State"]}</InputLabel>
              <Controller
                name="state"
                control={control}
                defaultValue=""
                rules={{ required: translations["State is required"] }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="state-label"
                    label={translations["State"]} // ensures the label moves when an item is selected
                    displayEmpty
                    variant="outlined"
                    error={!!errors.state}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getcontentanchorel: null, // This is important to make the menu display below the input
                    }}
                  >
                    <MenuItem value="AZ">AZ</MenuItem>
                    <MenuItem value="CA">CA</MenuItem>
                    <MenuItem value="NV">NV</MenuItem>
                    <MenuItem value="TX">TX</MenuItem>
                    <MenuItem value="WA">WA</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <Controller
              name="zipCode"
              control={control}
              defaultValue=""
              rules={{
                required: translations["Zip Code is required"],
                maxLength: { value: 5, message: "Maximum length is 5" },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={translations["Home Zip Code"]}
                  variant="outlined"
                  error={!!errors.zipCode}
                  helperText={errors.zipCode ? errors.zipCode.message : null}
                />
              )}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel id="householdSize-label">
                {translations["Household Size"]}
              </InputLabel>
              <Controller
                name="householdSize"
                control={control}
                defaultValue={householdSize}
                rules={{ required: translations["Household Size is required"] }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="householdSize-label"
                    label={translations["Household Size"]}
                    displayEmpty
                    variant="outlined"
                    error={!!errors.householdSize}
                    onChange={(e) => {
                      field.onChange(e);
                      setHouseholdSize(e.target.value);
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getcontentanchorel: null, // This is important to make the menu display below the input
                    }}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <Controller
              name="mobilePhone"
              control={control}
              defaultValue=""
              rules={{
                required: translations["Mobile Phone is required"],
                validate: (value) => {
                  const numericValue = value.replace(/\D/g, "");
                  return (
                    numericValue.length === 10 ||
                    translations["Mobile Phone should have 10 digits"]
                  );
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(
                      normalizeInput(e.target.name, e.target.value)
                    );
                  }}
                  label={translations["Mobile Phone (10 digits)"]}
                  variant="outlined"
                  error={!!errors.mobilePhone}
                  helperText={
                    errors.mobilePhone ? errors.mobilePhone.message : null
                  }
                />
              )}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={Object.keys(errors).length > 0}
              sx={{ mt: 2 }}
            >
              {translations["Get Started"]}
            </Button>
          </form>
        </CardContent>
      </Card>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ textAlign: "center", mt: 1 }}
          id="alert-dialog-title"
        >
          {translations["No Match Found"]}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="body1"
            sx={{ textAlign: "center" }}
            id="alert-dialog-description"
          >
            {translations["Check your information and try again."]} <br />{" "}
            {translations["If the issue persists, please call:"]}
          </DialogContentText>
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: "center", mt: 2, mb: 4 }}
          >
            <Link
              href="tel:+19493295880"
              style={{ color: "#00A0A8", textDecoration: "none" }}
            >
              <strong>(949) 329-5880</strong>
            </Link>
          </Typography>
          <DialogContentText
            variant="body2"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              maxWidth: "75%",
              margin: "auto",
            }}
            id="alert-dialog-note"
          >
            {
              translations[
                "Note: Ensure you are using the same information on file with your employer."
              ]
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mb: 1 }}></DialogActions>
      </Dialog>
    </Container>
  );
}

export default AuthenticationScreen;
