import React, { useState, useContext } from "react";
import { DataContext } from "../contexts/dataContexts";
import { useLanguage } from "../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import WaiveReasonModal from "./WaiveReasonModal";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Collapse,
  Button,
  Container,
  Box,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";

// Styled components for specific design requirements
const SmallFormControlLabel = styled(FormControlLabel)({
  ".MuiFormControlLabel-label": {
    fontSize: "0.7rem", // Smaller font for the checkbox label
  },
});

const IndentedTypography = styled(Typography)({
  fontSize: "0.7rem", // Smaller font for the expanded content
  fontStyle: "italic", // Italic font style
  marginLeft: "16px", // Indentation
});

const StyledFormControlLabel = styled(FormControlLabel)({
  ".MuiFormControlLabel-label": {
    fontWeight: "bold", // make the text bold
    fontSize: "0.8rem", // smaller text size
  },
});

function DisclosureScreen() {
  const [acknowledged, setAcknowledged] = useState(false);
  const [contactRequested, setContactRequested] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [signature, setSignature] = useState("");
  const navigate = useNavigate();
  const { utmData, setPostData, postData } = useContext(DataContext);
  const { translations } = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const [waiverReason, setWaiverReason] = useState("");
  const handleSaveReason = (selectedReason) => {
    setWaiverReason(selectedReason);
    setShowModal(false);
  };

  //handle error dialog for no match found
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleContinueClick = async () => {
    if (acknowledged && signature.trim()) {
      let updatedPostData = {
        ...postData,
        "Acknowledged Disclosure": true,
        "Contact Consent": contactRequested,
        signed: true,
        status: "Passive",
        passiveWaiverTime: new Date().toISOString(),
        signature,
      };

      if (utmData !== "waive") {
        updatedPostData.waiverReason = "";
        await postDataToEndpoint(updatedPostData);
      } else {
        setPostData(updatedPostData); // Set postData before opening modal
        setShowModal(true);
      }
    } else {
      handleOpenDialog();
    }
  };

  const postDataToEndpoint = async (data) => {
    try {
      const response = await fetch(
        "https://m4u8nxs6t1.execute-api.us-west-2.amazonaws.com/dev/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Failed to save data.");
      }

      setPostData(data);

      if (utmData === "waive") {
        navigate("/confirmation");
      } else if (utmData === "akash") {
        window.location.href = "https://enrollme.sureco.com/login";
      } else {
        navigate("/intake");
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert("Failed to save data. Please try again.");
    }
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleSignatureChange = (event) => {
    const { value } = event.target;
    // Remove non-alphabetic characters
    const alphabeticOnly = value.replace(/[^a-zA-Z\s]/g, "");

    // Now, call setSignature with the cleaned-up value
    setSignature(alphabeticOnly);
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        mb: 5,
      }}
    >
      <Box sx={{ my: 4, typography: "h4", textAlign: "center" }}>
        {translations["Employee Acknowledgment"]}
      </Box>
      <Card variant="outlined" sx={{ borderColor: "transparent" }}>
        <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={acknowledged}
                onChange={(e) => setAcknowledged(e.target.checked)}
                inputProps={{ "aria-label": "acknowledge terms" }}
                color="primary"
              />
            }
            label={translations["Ack1"]}
          />
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ ml: "auto" }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <IndentedTypography paragraph>
              {translations["Ack1a"]}
            </IndentedTypography>
          </CardContent>
        </Collapse>
      </Card>
      <Card variant="outlined" sx={{ borderColor: "transparent", mt: 2 }}>
        <Box sx={{ p: 2 }}>
          <SmallFormControlLabel
            control={
              <Checkbox
                checked={contactRequested}
                onChange={(e) => setContactRequested(e.target.checked)}
                name="contactRequest"
                color="primary"
              />
            }
            label={translations["Ack2"]}
          />
        </Box>
      </Card>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="signature"
        label={translations["Signature"]}
        type="text"
        id="signature"
        InputProps={{
          style: { fontFamily: "Brush Script MT" },
        }}
        value={signature}
        onChange={handleSignatureChange}
        sx={{ mt: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleContinueClick}
        sx={{ mt: 3, mb: 2, width: "100%", textTransform: "none" }}
      >
        {translations["Continue"]}
      </Button>
      <WaiveReasonModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSaveReason={handleSaveReason}
        postDataToEndpoint={postDataToEndpoint}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            variant="body1"
            sx={{ textAlign: "center" }}
            id="alert-dialog-description"
          >
            Please acknowledge the terms and provide your signature.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", mb: 1, textTransform: "none" }}
        >
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="contained"
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default DisclosureScreen;
